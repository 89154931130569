import * as React from "react";
import logo from "./IT_CITY_LOGO_COLOR.png";
import "./Home.css";

export default function Home() {
  function redirect() {
    setTimeout(myURL, 15000);
  }

  function myURL() {
    document.location.href = "https://permits.roundrocktexas.gov/";
  }

  redirect();

  return (
    <div>
      <div className="city-logo-container">
        <img className="city-logo-it" src={logo} alt="cit it logo" />
      </div>
      <div className="notice-title-container">
        <h1>We have a new permitting system!</h1>
      </div>
      <div className="notice-message-container">
        <p>
          The City of Round Rock has moved to a new permitting system. Go to{" "}
          <a href="https://permits.roundrocktexas.gov">
            permits.roundrocktexas.gov
          </a>{" "}
          starting May 15th, 2023 to submit and track all permit applications.
        </p>
      </div>
    </div>
  );
}
